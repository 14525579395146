/* Contact Styles */
.about {
    min-height: 100vh;
    width: 100vw;
    text-align: center;
    padding-top: 11rem;
    
  }
  
  .about .heading {
    text-align: center;
    padding: 2rem 0;
    padding-bottom: 1rem;
    font-size: 3.5rem;
    color: var(--dark);
  }
  
  .about .heading span {
    background: var(--primary-base);
    color: var(--white);
    display: inline-block;
    padding: .5rem 3rem;
    clip-path: polygon(100% 0, 93% 50%, 100% 99%, 0% 100%, 7% 50%, 0% 0%);
  }
  
.about .item-container {
    display: flex;
    flex-wrap: wrap;
    gap: 2.5rem;
    padding-top: 5rem;
    padding-bottom: 2rem;
}


.about .text-container {
  margin-top: 2rem;
  text-align: left;
}

.about .text-container h1 {
  margin-top: 3rem;
  font-size: 2rem;
}

.about .text-container p {
  font-size: 2rem;
  color: var(--gray);
  text-align: justify;
}

.about .item-container .item {
  flex: 1 1 25rem;
  background: var(--white);
  padding: 1.5rem;
  border: .1rem solid rgba(0, 0, 0, .2);
  border-radius: .6rem;
  box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .1);
  text-align: center;
}
  
.about .item-container .item img {
  width: 50rem;
  height: 30rem;
}
  
.about .item-container .item h3 {
  font-size: 1.5rem;
  color: var(--gray);
  padding: 1rem 0;
}

.about .item-container .item p {
  font-size: 1.5rem;
  color: var(--gray);
}


/* Midea Queries */
@media (max-width: 1180px) {
  .about{
    padding-top: 13rem;
  }
 
}


@media (max-width: 991px) {
  .about{
    padding-top: 13rem;
  }
}


@media (max-width: 768px){
  .about{
    padding-top: 12rem;
  }

  .about .item-container{
    display: flex;
    flex-direction: column;
  }

}

@media (max-width: 450px) { 
  html{
    font-size: 50%;
  }
  .about .text-container p {
    font-size: 1.5rem;
  }
}

@media (max-width: 320px){
  html{
    font-size: 50%;
  }
  .about .text-container p {
    font-size: 1.5rem;
  }
}
