/* Section Home Styles 
section {
  padding: 2rem 9%;
 }
*/ 

 .home {
   min-height: 100vh;
   display: flex;
   align-items: center;
   justify-content: center;
   flex-flow: column;
   position: relative;
   z-index: 0;
   background: url(../../assets/images/banner-home.jpg) no-repeat;
   background-position: center;
   background-size: cover;
   background-attachment: fixed;
   padding-top: 17rem;
   padding-bottom: 10rem;
 }

 
 .home .banner {
   padding-top: 20rem;
   text-align: right;
 }

 .home .banner .subtitle-text {
    margin-top: 0.5rem;
    padding: 0 1rem 0 1rem;
    background: rgba(0, 0, 0, .5);
 }
 
 .home .banner .subtitle-text p {
   font-size: 3.5rem;
   font-weight: 600;
   color: var(--white);
   text-transform: uppercase;
   text-shadow: 0 .3rem .5rem rgba(0, 0, 0, .1);
   margin-bottom: 5rem;
   
 }
 
 .home .banner  h3 {
   font-size: 2rem;
   font-weight: 600;
   color: var(--white);
   padding: .5rem 0;
 }
 
 .home .banner  .btn {
  margin-top: 1rem;
  display: inline-block;
  padding: .8rem 3rem;
  font-size: 1.7rem;
  border-radius:  .5rem;
  border: .3rem solid var(--dark);
  color: var(--dark);
  cursor: pointer;
 }
 
 .home .banner  .btn {
   border: .3rem solid var(--white);
   color: var(--white);
 }

 .home .banner  .btn:hover {
  color: var(--dark);
  background: var(--white);
  border: none;
}


/* .home .overlay {
  
} */

.home .hero-shape {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.home .hero-shape img {
  width: 100%;
  display: block;
}
 
 
 /* Home-Group Services Section */
 .group-service {
   width: 100vw;
   text-align: center;
 }
 
 .group-service .heading {
   text-align: center;
   padding: 2rem 0;
   padding-bottom: 1rem;
   font-size: 3.5rem;
   color: var(--dark);
 }
 
 .group-service .heading span {
   background: var(--primary-base);
   color: var(--white);
   display: inline-block;
   padding: .5rem 2rem;
   clip-path: polygon(100% 0, 93% 50%, 100% 99%, 0% 100%, 7% 50%, 0% 0%);
 }
 
 .group-service .row {
   /* margin: 2rem 0; */
   padding: 0 2rem;
   display: flex;
   align-items: center;
   justify-content: center;
   margin-top: 4rem;
   margin-bottom: 8rem;
 }
 

 .group-service .row .image img{
   width: 35rem;
   height: 30rem;
   border-radius: 20px;
 }
 
 .group-service .row .content{
   text-align: left;
   padding: 0 3rem;
 }
 
 .group-service .row .content h3 {
   font-size: 2.3rem;
   color: var(--dark);
   margin-bottom: 1rem;
 }
 
 .group-service .row .content h3::after{
   content: '';
   position: absolute;
   width: 100px;
   height: 3px;
   display: block;
   margin: 0 auto;
   background: var(--primary-base);
 }
 
 .group-service .row .content p {
   font-size: 1.5rem;
   color: var(--gray);
   padding: 1rem 0;
 }
 
 .group-service .row .content .btn {
   margin-top: 1rem;
   display: inline-block;
   padding: .5rem 2.5rem;
   font-size: 1.7rem;
   border-radius:  5rem;
   background: var(--primary-base);
   color: var(--white);
   cursor: pointer;
   box-shadow: var(--box-shadow);
 }
 
 .group-service .row .content .btn:hover{
   opacity: .8;
 }
 
/* Parallax Section */
div.module {
  display: flex;
  /* flex-direction: column; */
  flex-flow: column;
  justify-content: center;
  min-height: 140vh !important;
  width: 100%;
  position: relative;
  overflow: hidden;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;

  -webkit-background-size: cover;
  -moz-background-size: cover;
  -moz-background-size: cover;
}

div.module::after {
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 8;
  background: linear-gradient(to bottom, rgba(0,0,0,0) 0, rgba(0,0,0,0) 40%, #000 100%);

  background: -moz-linear-gradient(to bottom, rgba(0,0,0,0) 0, rgba(0,0,0,0) 40%, #000 100%);
  background: -webkit-linear-gradient(to bottom, rgba(0,0,0,0) 0, rgba(0,0,0,0) 40%, #000 100%);
  background: -o-repeating-linear-gradient(to bottom, rgba(0,0,0,0) 0, rgba(0,0,0,0) 40%, #000 100%);
}

div.module.parallax {
  background-image: url("../../assets/images/img-parallax.png");
  object-fit: cover;
}





 /* SWIPER SLIDER */




 

 /* Midea Queries */
@media (max-width: 768px){
 
   .group-service .row {
     flex-flow: column-reverse;
     margin-bottom: 1rem;
     margin-top: 2rem;
   }
 
   .group-service .row:nth-child(even) {
     flex-flow: column;
   } 

   .group-service .row .image {
    margin-bottom: 1rem;
    margin-top: 1rem;
   }
 
   .group-service .row .image img {
     width: 80vw;
   } 
 
   .group-service .row .content {
     padding: 0;
   }

 }
 
 @media (max-width: 450px) {
 
   html{
     font-size: 50%;
   }

   .heading {
     font-size: 1.5rem;
   }

   .group-service .row .image img {
      /* width: 60vw; */
      width: 35rem;
      height: 30rem;
    } 

   .image-group .image-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 3rem;
  }

  div.module {
    display: none;
  }
 
 }
 
 @media (max-width: 320px) {
 
  html{
    font-size: 50%;
  }

  .heading {
    font-size: 1.5rem;
  }

  .group-service .row .image {
    margin-inline: auto;
  }

  .group-service .row .image img {
    width: 25rem;
    height: 20rem;
  }

  .image-group {
    padding: 0;
  }

  .image-group .image-container {
   display: flex;
   align-items: center;
   flex-direction: column;
   gap: 2rem;
   margin-bottom: 3rem;
   margin: 0;
 }

 .image-group .image-container .item {
   padding: .5rem;
 }

 .image-group .image-container .item img {
  width: 30rem;
  height: 25rem;
  transition: .7s ease-in-out;
}

div.module {
  display: none;
}

}