/* Header Styles */


.header {
  position: fixed;
  top: 0; left: 0;
  right: 0;
  background: var(--light-white);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content:  space-between;
  padding: 1rem 9%;
  transition: all 0.5s ease;

}

.header.navScroll {
  position: fixed;
  background: var(--light-white);
}

.header.navScroll .navbar{
  right: -22rem;
}


/*.header.navScroll .navbar{
  right: -22rem;
}*/

header .logoImg img {
  width: 9rem;
  height: 8rem;
}

header .navbar a {
  color: var(--dark);
  font-size: 2rem;
  font-weight: 500;
  margin: 0 3rem;
  position: relative;
  right: -25rem; 
}


header .navbar a:hover{
  color: var(--primary-base);
  border-bottom: 3px solid var(--primary-base);
  padding-right: 2rem;
  font-weight: 500;
} 

#menu-btn {
  display: none;
}





/* Midea Queries */
@media (max-width: 1180px) {

  .header{
    padding: 2rem;
  }

  .header .navbar {
    right: 1rem;
  }

  .header .navbar a {
    right: -15rem;
  }
}


@media (max-width: 991px) {

  html{
    font-size: 55%;
  }

  .header{
    padding: 2rem;
  }

  .header .navbar a{
    right: -8rem;
  }
}


@media (max-width: 768px){

  #menu-btn {
    display: inline-block;
  }

  .header.navScroll .navbar{
    display: none;
  }

  .header .navbar {
    position: absolute;
    top: 110%;
    right: -110%;
     /* right: 2rem;  */
    width: 30rem;
    box-shadow: var(--box-shadow);
    border-radius: .5rem;
    background: var(--white);

  }

  .header .navbar#active {
    position: absolute;
    top: 110%;
    right: 1rem;
    transition: .4s linear;
    width: 30rem;
    box-shadow: var(--box-shadow);
    border-radius: .5rem;
    background: var(--white);
  }

  .header .navbar a{
    font-size: 2rem;
    margin: 2rem 2rem;
    display: block;
    right: -5rem;
  }


  .header .navbar a:hover{
    color: var(--primary-base);
    border-bottom: 3px solid var(--primary-base);
    padding-right: 2rem;
    font-weight: 500;
    width: 60px;
  }

  .header div span {
    height: 4.5rem;
    width: 4.5rem;
    line-height: 4.5rem;
    border-radius: .5rem;
    background: var(--gray-light);
    color: var(--dark);
    font-size: 2rem;
    margin-left: .3rem;
    text-align: center;
  }

}

@media (max-width: 450px) {

  html{
    font-size: 50%;
  }

  .heading {
    font-size: 1.5rem;
  }

  .header .navbar {
    right: -130%;
  }

}

@media (max-width: 314px) {

  html{
    font-size: 50%;
  }

  .heading {
    font-size: 1.5rem;
  }

  .header .navbar {
    right: -140%;
  }

}

@media (max-width: 296px) {

  html{
    font-size: 50%;
  }

  .heading {
    font-size: 1.5rem;
  }

  .header .navbar {
    right: -140%;
  }

}