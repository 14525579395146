
.clients-group {
    width: 100vw;
    height: 100%;
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .clients-group .heading {
    text-align: center;
    padding: 2rem 0;
    padding-bottom: 1.5rem;
    font-size: 3.5rem;
    color: var(--dark);
  }
  
  .clients-group .heading span {
    background: var(--primary-base);
    color: var(--white);
    display: inline-block;
    padding: .5rem 2rem;
    clip-path: polygon(100% 0, 93% 50%, 100% 99%, 0% 100%, 7% 50%, 0% 0%);
  }
  
  .clients-group h1 {
    font-size: 2rem;
    padding-bottom: 2rem;
  }
  
  
  .clients-group .slide .fa-quote-right {
    position: absolute;
    top: 2rem;
    right: 2rem;
    font-size: 4rem;
    color: var(--gray-light);
    display: none;
  }
  
  .clients-group .slide .client {
    display: flex;
    gap: 1.5rem;
    align-items: center;
    padding-bottom: 1.5rem;
  }
  
  .clients-group .slide .client img {
    height: 7rem;
    width: 7rem;
    border-radius: 50%;
    object-fit: cover;
  }
  
  .clients-group .slide .client h3 {
    color: var(--dark);
    font-size: 1.5rem;
    padding-bottom: .5rem;
  }
  
  .clients-group .slide .client i {
    font-size: 1.3rem;
    color: var(--primary-base);
  }
  
  .clients-group .slide p {
    font-size: 1.5rem;
    line-height: 1.8;
    color: var(--gray);
  }
  

 
 /* Midea Queries */
@media (max-width: 768px){
 

}

@media (max-width: 450px) {


}

@media (max-width: 320px) {
  html{
    font-size: 50%;
  }
  .clients-group .slide .fa-quote-right {
    top: 0.5rem;
    right: 812px;
  }
  .clients-group .slide p {
    font-size: 1.2rem;
    line-height: 1.8;
    color: var(--gray);
  }
  

} 