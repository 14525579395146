@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800&display=swap');

*{
  font-family: 'Poppins', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
  border: none;
  text-decoration: none;
  text-transform: capitalize;
  transition: all .2s linear;
}

html {
  font-size: 62.5%;
  overflow-x: hidden;
  scroll-behavior: smooth;
  scroll-padding-top: 7rem;
}

:root {
  --dark: #000;
  --white: #fff;
  --light-white: #eee;
  --gray: #7A7979;
  --gray-light: #ccc;

  --primary-dark: #001584;
  --primary-base: #2C7AED;

  --secundary-base: #86D7FA;
  --box-shadow: 0 .5rem 2rem rgba(0, 0, 0,.1);
  --border:.5rem solid rgba(0, 0, 0,.1);

  
  --gradient: linear-gradient(45deg, rgba(8, 84, 178, 83), rgba(17, 204, 216, 100));
}

section {
  max-width: 1170px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 16px;
  padding-right: 16px;
}
